import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment } from "react";

import CTABox from "components/CTAs/CTABox";
import ProfileNavigationSidebarStyles from "components/Profiles/ProfileNavigationSidebar/ProfileNavigationSidebarStyles";

import HomepageSidebarCategories from "./HomepageSidebarCategoriesAsync";
import HomepageSidebarCreators from "./HomepageSidebarCreatorsAsync";
import HomepageSidebarMenu from "./HomepageSidebarMenuAsync";
import HomepageSidebarMore from "./HomepageSidebarMoreAsync";
import HomepageSidebarMyActivity from "./HomepageSidebarMyActivity";
import HomepageSidebarInternalUsers from "./HomepageSidebarMyActivity/HomepageSidebarInternalUsers";
import HomepageSidebarMyCreators from "./HomepageSidebarMyCreatorsAsync";
import HomepageSidebarMyPodcasts from "./HomepageSidebarMyPodcastsAsync";
import HomepageSidebarPodcasts from "./HomepageSidebarPodcastsAsync";
import HomepageSidebarProfile from "./HomepageSidebarProfileAsync";
import HomepageSidebarProLinks from "./HomepageSidebarProLinksAsync";
import HomepageSidebarSection from "./HomepageSidebarSection";
import HomepageSidebarSuggestedFollows from "./HomepageSidebarSuggestedFollowsAsync";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";
import useWindowSize from "hooks/useWindowSize";

import PageStyles from "styles/PageStyles";

const baseStyles = { ...PageStyles, ...ProfileNavigationSidebarStyles };

const HomepageSidebar = (props) => {
  const { inFeed } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");
  const user = useLoggedInUser();
  const hasPro = useUserHasPro();

  const renderUserSections = () => {
    const creatorsSection = user.get("managed_creators_count") > 0 && (
      <HomepageSidebarMyCreators />
    );
    const podcastsSection = user.get("claimed_podcasts_count") > 0 && (
      <HomepageSidebarMyPodcasts />
    );

    if (hasPro) {
      return (
        <Fragment>
          <HomepageSidebarInternalUsers />
          <HomepageSidebarMyActivity />
          {podcastsSection}
          {creatorsSection}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {podcastsSection}
        {creatorsSection}
        <HomepageSidebarMyActivity />
      </Fragment>
    );
  };

  const renderTrendingSections = () => {
    if (hasPro) {
      return (
        <Fragment>
          <HomepageSidebarCategories />
          <HomepageSidebarPodcasts />
          <HomepageSidebarCreators />
          {user && <HomepageSidebarSuggestedFollows />}
          <HomepageSidebarMenu />
          <HomepageSidebarMore />
          <HomepageSidebarProLinks />
        </Fragment>
      );
    }

    return (
      <Fragment>
        {user && <HomepageSidebarSuggestedFollows />}
        <HomepageSidebarCategories />
        <HomepageSidebarPodcasts />
        <HomepageSidebarCreators />
        <HomepageSidebarMenu />
        <HomepageSidebarMore />
      </Fragment>
    );
  };

  return (
    <aside className={css(styles.sidebarContainer)}>
      {user ? (
        <HomepageSidebarProfile user={user} mobile={mobile} />
      ) : (
        <HomepageSidebarSection header={false} inFeed={inFeed}>
          <CTABox />
        </HomepageSidebarSection>
      )}

      {user && renderUserSections()}
      {(!mobile || inFeed) && renderTrendingSections()}
    </aside>
  );
};

HomepageSidebar.propTypes = {
  user: PropTypes.instanceOf(Map),
  inFeed: PropTypes.bool,
};

HomepageSidebar.defaultProps = {
  user: null,
  inFeed: false,
};

export default memo(HomepageSidebar);
